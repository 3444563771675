.main-content {
  max-width: 1440px;
  margin: 30px auto 25px auto;
  padding-right: 20px;
  padding-left: 20px;
}

.topHeaderContainer {
  margin-bottom: 30px;
  padding-left: 30px;
}

.contentHeader {
  font-size: 20px;
  font-weight: 400;
  line-height: 32px;
  color: #000000;
  width: 100%;
}

.searchActions {
  max-width: 455px;
  /* margin-right: auto; */
  margin-left: auto;
}

.searchContainer {
  text-align: center;
}

.search {
  background: #ffffff;
  border: 1px solid #ebebeb;
  box-sizing: border-box;
  border-radius: 3px;
  padding: 2px 4px 2px 2px;
  width: 455px;
}

::placeholder {
  color: #b0b0b0;
}

.search input {
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  padding: 10px 15px;
  border: unset;
  outline: none;
  width: 100%;
}

.search button {
  height: 40px;
  width: 45px;
  color: #ffffff;
  background: #8ac04a;
  border: unset;
  border-radius: 1px;
  cursor: pointer;
  outline: none;
}

.search button:hover {
  opacity: 0.8;
}

.documentsList {
  background-color: #ffffff;
  padding-bottom: 30px;
}

.filterContainer {
  /* padding: 40px 35px 35px; */
  padding: 40px 20px 40px;
  /* display: none; */
}

.actions span {
  font-weight: 400;
  font-size: 16px;
  line-height: 32px;
  color: #000000;
  display: block;
}

.actions button {
  font-weight: 400;
  font-size: 16px;
  line-height: 32px;
  color: #b0b0b0;
  background-color: #f9f9f9;
  border: 1px solid #ebebeb;
  box-sizing: border-box;
  border-radius: 3px;
  padding: 5px 15px;
  cursor: pointer;
}

.actions button:not(:last-child) {
  margin-right: 5px;
}

.actions button:hover {
  background-color: #ebebeb;
}

.actions button i {
  font-size: 15px;
  margin-right: 8px;
}

.searchContainer span {
  font-weight: 400;
  font-size: 16px;
  line-height: 32px;
  color: #000000;
  margin-bottom: 10px;
  display: block;
}

.quickActions {
  background-color: #ffffff;
  margin-bottom: 10px;
  padding: 60px 60px 70px;
}

.quickActions h2 {
  font-weight: 400;
  font-size: 30px;
  line-height: 36px;
  text-align: center;
  color: #272828;
}

.quickActions p {
  font-weight: 300;
  font-size: 16px;
  line-height: 25px;
  text-align: center;
  color: #7d7d7d;
  margin: 15px 0 40px;
}

.quickActions ul {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 10px;
  max-width: 640px;
  /* max-width: 550px; */
  margin: auto;
}

.quickActions ul li {
  color: #b0b0b0;
  background-color: #f9f9f9;
  border: 1px solid #ebebeb;
  box-sizing: border-box;
  border-radius: 3px;
  padding: 25px 15px;
  position: relative;
  cursor: pointer;
}

.quickActions ul li:hover {
  color: #ffffff;
  background-color: #8bc334;
  border: 1px solid #8bc334;
}

.quickActions > ul li {
  /* display: block; */
  text-align: center;
}
.quickActions svg {
  margin: auto;
}

.quickActions ul li span {
  font-size: 16px;
  line-height: 32px;
  margin-top: 15px;
}

.fileList {
  margin-top: 0;
}

.fileList .fileListHeader .fileListCol {
  background-color: #388cef;
  align-items: center;
}

.fileList .fileListCol {
  display: flex;
  padding: 20px;
}

.fileList .fileListBody .fileListCol {
  border-bottom: 1px solid #e7e7e7;
}

.fileList .fileListCol > li:nth-child(1) {
  width: 35%;
}

.fileList .fileListCol > li:nth-child(2) {
  width: 16%;
}

.fileList .fileListCol > li:nth-child(3) {
  width: 18%;
}

.fileList .fileListCol > li:nth-child(4) {
  width: 10%;
}

.fileList .fileListCol > li:nth-child(5) {
  width: 15%;
}

.fileList .fileListCol > li:nth-child(6) {
  width: 6%;
  text-align: right;
  position: relative;
  height: 50px;
}

.fileList .fileListHeader > ul > li {
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  color: #ffffff;
  cursor: pointer;
  position: relative;
}

.fileList .fileListHeader ul > li.filterActive .serviceTypeFilter {
  display: block;
}

.fileList .fileListHeader ul > li > svg {
  margin-left: 4px;
}

.fileList .fileListHeader ul > li .serviceTypeFilter {
  position: absolute;
  top: 100%;
  background-color: #8ac04a;
  border: 1px solid #7bae3e;
  box-sizing: border-box;
  border-radius: 3px;
  width: 210px;
  z-index: 9;
  display: none;
}

.fileList .fileListHeader ul > li .serviceTypeFilter li {
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: #ffffff;
  display: flex;
  padding: 10px 15px;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.fileList .fileListHeader ul > li .serviceTypeFilter li.selected {
  background-color: #96c65c;
}

.fileList .fileListHeader ul > li .serviceTypeFilter li:hover {
  opacity: 0.8;
}

.fileList .fileListHeader ul > li .serviceTypeFilter li * {
  color: rgb(0 0 0 / 20%);
}

.fileList .fileListHeader ul > li .serviceTypeFilter li.selected * {
  color: #ffffff;
}

.fileList .fileListHeader ul > li .serviceTypeFilter li:not(:last-child) {
  border-bottom: 1px solid #7bae3e;
}

.fileList .fileListHeader ul > li .serviceTypeFilter li button {
  font-size: 16px;
  line-height: 19px;
  text-transform: uppercase;
  color: #ffffff;
  cursor: pointer;
  background: rgb(255 255 255 / 20%);
  border: unset;
  box-sizing: border-box;
  border-radius: 3px;
  outline: none;
  width: 100%;
  padding: 10px;
}

.fileList .fileListBody .fileListCol > li {
  font-weight: 300;
  font-size: 16px;
  line-height: 25px;
  color: #7d7d7d;
}

.fileList .fileListCol > li:not(:last-child) {
  padding-right: 10px;
}

.fileList .fileListHeader .fileListCol > li:last-child button {
  color: #ffffff;
  background-color: #449aff;
  border: 1px solid #1e73d6;
}

.fileList .fileListHeader .fileListCol > li:last-child button.active,
.fileList .fileListHeader .fileListCol > li:last-child button:hover {
  color: #449aff;
  background-color: #ffffff;
  border: 1px solid #ffffff;
}

.fileList .fileListCol > li:last-child button {
  font-size: 19px;
  color: #b0b0b0;
  background: #f9f9f9;
  border: 1px solid #ebebeb;
  box-sizing: border-box;
  border-radius: 3px;
  width: 50px;
  height: 50px;
  padding: 0;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.fileList .fileListCol > li:last-child button:hover,
.fileList .fileListCol > li:last-child button.active {
  color: #ffffff;
  background-color: #8ac04a;
  border: 1px solid #7bae3e;
  box-sizing: border-box;
}

.fileList .fileListCol > li:last-child button.active {
  border-radius: 3px 3px 0 0;
}

.fileList .fileListCol > li:last-child button.active ~ ul {
  display: block;
}

.fileList .fileListCol > li:last-child .settingMenu {
  position: absolute;
  top: 95%;
  right: 0;
  background-color: #8ac04a;
  border: 1px solid #7bae3e;
  box-sizing: border-box;
  border-radius: 3px 0px 3px 3px;
  width: 180px;
  display: none;
  z-index: 9;
}

.fileList .fileListCol > li:last-child .settingMenu li a {
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: #ffffff;
  display: flex;
  align-items: center;
  padding: 5px 10px;
}

.fileList .fileListCol > li:last-child .settingMenu li:hover {
  opacity: 0.8;
}

.fileList .fileListCol > li:last-child .settingMenu li:not(:last-child) a {
  border-bottom: 1px solid #7bae3e;
}

.fileList .fileListCol > li:last-child .settingMenu li a > * {
  margin-right: 8px;
}

.fileList .serviceName {
  display: flex;
}

.fileList .fileIcon img {
  width: 100%;
  max-width: 36px;
}
.fileList .fileIconContainer {
  margin-right: 10px;
}

.fileList .fileIcon {
  background-color: #f9f9f9;
  border: 1px solid #ebebeb;
  box-sizing: border-box;
  border-radius: 3px;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.fileList .fileListBody ul:hover .fileIcon {
  background-color: #8ac04a;
  border: 1px solid #7bae3e;
}

.fileList .fileListBody ul .fileIcon svg {
  width: 36px;
  fill: #b0b0b0;
}

.fileList .fileListBody ul:hover .fileIcon svg {
  fill: #ffffff;
}

.fileList .fileContent span:first-child {
  font-weight: 400;
  line-height: 28px;
  color: #000000;
}
.fileList .fileContent span {
  display: block;
}

.fileList .fileListBody > ul:hover {
  background-color: rgba(56, 140, 239, 0.05);
  cursor: pointer;
}

.fa-plus-circle {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px !important;
}

.contactDetails {
  display: flex;
  flex-wrap: wrap;
}

.contactDetails a {
  font-size: 16px;
  text-transform: uppercase;
  color: #b0b0b0;
  background-color: #f9f9f9;
  border: 1px solid #ebebeb;
  box-sizing: border-box;
  border-radius: 3px;
  width: 125px;
  height: 50px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.contactDetails a:hover {
  color: #ffffff;
  background-color: #8bc334;
  border: 1px solid #8bc334;
}

.contactDetails a:first-child {
  margin-bottom: 10px;
}

.contactDetails a > * {
  margin-right: 8px;
}

@media screen and (min-width: 1024px) {
  .fileList .fileIconContainer {
    margin-right: 20px;
  }
}

@media screen and (min-width: 1100px) {
  .fileList .fileListHeader > ul > li {
    font-size: 20px;
    line-height: 28px;
  }
}

@media screen and (min-width: 1150px) {
}

@media screen and (min-width: 1200px) {
}

@media screen and (min-width: 1225px) {
}

@media screen and (min-width: 1270px) {
}

@media screen and (min-width: 1440px) {
  .contactDetails a:first-child {
    margin-bottom: 0;
    margin-right: 10px;
  }

  .fileList .fileListCol > li:nth-child(2) {
    width: 15%;
  }

  .fileList .fileListCol > li:nth-child(3) {
    width: 15%;
  }

  .fileList .fileListCol > li:nth-child(4) {
    width: 10%;
  }

  .fileList .fileListCol > li:nth-child(5) {
    width: 20%;
  }

  .fileList .fileListCol > li:nth-child(6) {
    width: 5%;
  }
}
